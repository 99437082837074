import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import autoAnimate from '@formkit/auto-animate';

export declare type FormCheckType = 'checkbox' | 'radio' | 'switch';

function TechnologyItem({
  item,
  segment,
  data,
  setData
}: {
  item: TechItem;
  segment: Segment;
  data: Segment[];
  setData: Function;
}) {
  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col xs="auto">
          <Form.Check
            type={item.inputType}
            id={item.name}
            name={segment.title}
            defaultChecked={item.checked}
            onChange={(el) => {
              setData(item, segment, el.target.checked);
            }}
          />
        </Col>
        <Col xs="auto">
          <div
            style={{
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <img src={item.icon} alt={item.name} width="40px" />
          </div>
        </Col>
        <Col>
          <h6>{item.name}</h6>
        </Col>
      </Row>
      {item.checked && item.inputs.length > 0 && (
        <Row>
          <Col className="py-3 px-3">
            {item.inputs.map((itemInput) => {
              return (
                <Row key={`${item.name}-${itemInput.label}-4`}>
                  <Col>
                    <Form.Group as={Row}>
                      <Form.Label column xs={3}>
                        {itemInput.label}
                      </Form.Label>
                      <Col>
                        {itemInput.type == 'text' && (
                          <Form.Control type="text" defaultValue={itemInput.default} />
                        )}
                        {itemInput.type == 'switch' && (
                          <Form.Check type="switch" defaultValue={itemInput.default} />
                        )}
                      </Col>
                      <Col>
                        <Form.Text>
                          {itemInput.default && <span>Default: {itemInput.default}</span>}
                        </Form.Text>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      )}
    </Container>
  );
}
function ProjectSegment({
  segment,
  data,
  setData
}: {
  segment: Segment;
  data: Segment[];
  setData: Function;
}) {
  return (
    <Accordion.Item eventKey={segment.title}>
      <Accordion.Header>
        <Container fluid>
          <Row className="align-items-center">
            <Col>
              <h5>{segment.title}</h5>
            </Col>
            <Col xs="auto">
              <span></span>
            </Col>
          </Row>
        </Container>
      </Accordion.Header>
      <Accordion.Body>
        {segment.techItems.map((techItem) => {
          return (
            <TechnologyItem
              item={techItem}
              segment={segment}
              key={`tech-item-${techItem.name}-5`}
              data={data}
              setData={setData}
            />
          );
        })}
      </Accordion.Body>
    </Accordion.Item>
  );
}

interface TechInput {
  label: string;
  type: string;
  default: string;
}
interface TechItem {
  name: string;
  description: string;
  icon: string;
  inputType: FormCheckType;
  inputs: TechInput[];
  checked: boolean;
}
interface Segment {
  title: string;
  techItems: TechItem[];
}

interface SummaryItem {
  segment: string;
  techItems: string[];
}

const initialData: Segment[] = [
  {
    title: 'Credentials Management',
    techItems: [
      {
        name: 'Manual',
        description: 'Store secrets with your runner or any other means',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/application_icons/dataops.png',
        checked: false,
        inputType: 'radio',
        inputs: []
      },
      {
        name: 'AWS Secrets Manager',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/aws-icon.png',
        checked: true,
        inputType: 'radio',
        inputs: [
          { label: 'Vault name', type: 'text', default: '' },
          { label: 'Secrets selection', type: 'text', default: '/dataops/' },
          { label: 'Secrets strip prefix', type: 'text', default: '/dataops/' }
        ]
      },
      {
        name: 'Azure Keyvualt',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/azure.png',
        checked: false,
        inputType: 'radio',
        inputs: []
      },
      {
        name: 'HashiCorp Vault',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/hashicorp.png',
        checked: false,
        inputType: 'radio',
        inputs: []
      }
    ]
  },
  {
    title: 'Snowflake Infrastructure as Code',
    techItems: [
      {
        name: 'Use DataOps SOLE',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/application_icons/dataops.png',
        checked: true,
        inputType: 'switch',
        inputs: []
      }
    ]
  },
  {
    title: 'Data Products',
    techItems: [
      {
        name: 'Use Data Products',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/application_icons/dataops.png',
        checked: true,
        inputType: 'switch',
        inputs: []
      }
    ]
  },
  {
    title: 'Data Ingestion',
    techItems: [
      {
        name: 'Matillion',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/matillion.png',
        checked: false,
        inputType: 'switch',
        inputs: []
      },
      {
        name: 'Talend',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/talend.png',
        checked: false,
        inputType: 'switch',
        inputs: [
          { label: 'Talend Management Console Task ID', type: 'text', default: '' },
          { label: 'Talend Management Console Region', type: 'text', default: 'eu' },
          { label: 'Talend Management Console Timeout', type: 'text', default: '300' },
          { label: 'Talend Management Console Job Parameters', type: 'text', default: '' }
        ]
      },
      {
        name: 'Fivetran',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/fivetran.png',
        checked: false,
        inputType: 'switch',
        inputs: []
      },
      {
        name: 'Informatica',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/informatica.png',
        checked: false,
        inputType: 'switch',
        inputs: []
      },
      {
        name: 'Stitch',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/stitch.png',
        checked: false,
        inputType: 'switch',
        inputs: []
      }
    ]
  },
  {
    title: 'Data Transformation',
    techItems: [
      {
        name: 'DataOps MATE',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/application_icons/dataops.png',
        checked: false,
        inputType: 'switch',
        inputs: [
          { label: 'Data Vault', type: 'switch', default: '' },
          { label: 'Kimball', type: 'switch', default: '' },
          { label: 'Star Schema', type: 'switch', default: '' },
          { label: 'Dimensional Modeling', type: 'switch', default: '' },
          { label: 'Custom', type: 'switch', default: '' }
        ]
      },
      {
        name: 'Matillion',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/matillion.png',
        checked: false,
        inputType: 'switch',
        inputs: []
      },
      {
        name: 'Talend',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/talend.png',
        checked: false,
        inputType: 'switch',
        inputs: [
          { label: 'Talend Management Console Task ID', type: 'text', default: '' },
          { label: 'Talend Management Console Region', type: 'text', default: 'eu' },
          { label: 'Talend Management Console Timeout', type: 'text', default: '300' },
          { label: 'Talend Management Console Job Parameters', type: 'text', default: '' }
        ]
      }
    ]
  },
  {
    title: 'Data Quality',
    techItems: [
      {
        name: 'DataOps MATE',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/application_icons/dataops.png',
        checked: false,
        inputType: 'switch',
        inputs: []
      },
      {
        name: 'Soda',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/soda.png',
        checked: false,
        inputType: 'switch',
        inputs: [
          {
            label: 'Soda Warehouse Path',
            type: 'text',
            default: '/dataops/soda/snowflake.template.yml'
          },
          { label: 'Soda Table Path', type: 'text', default: '/dataops/soda/tables/' }
        ]
      },
      {
        name: 'Montecarlo Data',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/montecarlo.png',
        checked: false,
        inputType: 'switch',
        inputs: []
      }
    ]
  },
  {
    title: 'Data Catalog',
    techItems: [
      {
        name: 'data.world',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/datadotworld.png',
        checked: false,
        inputType: 'switch',
        inputs: []
      },
      {
        name: 'Collibra',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/collibra.png',
        checked: false,
        inputType: 'switch',
        inputs: []
      },
      {
        name: 'Alation',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/alation.png',
        checked: false,
        inputType: 'switch',
        inputs: []
      }
    ]
  },
  {
    title: 'Documentation',
    techItems: [
      {
        name: 'Automatically create documentation',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/application_icons/dataops.png',
        checked: true,
        inputType: 'switch',
        inputs: []
      }
    ]
  },
  {
    title: 'Unified Observability',
    techItems: [
      {
        name: 'Enable unified observability',
        description: '',
        icon: 'https://dataops-public-assets.s3.eu-west-2.amazonaws.com/application_icons/dataops.png',
        checked: true,
        inputType: 'switch',
        inputs: []
      }
    ]
  }
];

function simulateNetworkRequest() {
  return new Promise((resolve) => setTimeout(resolve, 2000));
}

function PipelineBuilderView() {
  const [data, setData] = useState(initialData);
  const [showProjectBuilt, setProjectBuilt] = useState(false);
  const parentRef = useRef(null);

  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current);
    }
  }, [parentRef]);

  const summaryItems: SummaryItem[] = [];
  data.forEach((segment) => {
    const techItems: string[] = [];
    segment.techItems.forEach((techItem) => {
      if (techItem.checked) {
        techItems.push(techItem.name);
      }
    });
    if (techItems.length > 0) summaryItems.push({ segment: segment.title, techItems: techItems });
  });

  function updateData(item: TechItem, segment: Segment, checked: boolean) {
    const foundSegment = data.findIndex((currentSeg) => currentSeg.title == segment.title);
    const foundItem = data[foundSegment].techItems.findIndex(
      (currentItem) => currentItem.name == item.name
    );

    const newData = [...data];

    if (item.inputType == 'radio') {
      newData[foundSegment].techItems = newData[foundSegment].techItems.map((item) => {
        return { ...item, checked: false };
      });
    }
    newData[foundSegment].techItems[foundItem].checked = checked;
    setData(newData);
  }

  function LoadingButton() {
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
      if (isLoading) {
        simulateNetworkRequest().then(() => {
          setLoading(false);
          setProjectBuilt(true);
        });
      }
    }, [isLoading]);

    return (
      <Button
        disabled={isLoading}
        onClick={() => {
          setProjectBuilt(false);
          if (!isLoading) setLoading(true);
        }}
        style={{ color: 'white', fontWeight: 'bold' }}>
        {isLoading ? (
          <span>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}
            Building...
          </span>
        ) : (
          <span>Build project</span>
        )}
      </Button>
    );
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1>Project Builder</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              Use this tool to create a complete DataOps project that uses your pipeline
              technologies.
            </p>
            <p>
              Use the technologies from the list below to add them to your project. You can come
              back and add or change options in the future.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xs={2}></Col>
          <Col>
            <Form>
              <Accordion flush defaultActiveKey="Credentials Management">
                {data.map((projectSeg) => {
                  return (
                    <ProjectSegment
                      segment={projectSeg}
                      key={`segment-${projectSeg.title}-3`}
                      data={data}
                      setData={updateData}
                    />
                  );
                })}
              </Accordion>
            </Form>
          </Col>
          <Col xs={2}>
            <Card>
              <Card.Body>
                <Card.Title>Project summary</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">Review your choices</Card.Subtitle>
              </Card.Body>
              <ListGroup variant="flush" ref={parentRef}>
                {summaryItems.map((summaryItem) => {
                  return (
                    <ListGroup.Item key={`${summaryItem.segment}-2`}>
                      <div className="py-2">
                        <div>{summaryItem.segment}</div>
                        {summaryItem.techItems.map((techItem) => {
                          return (
                            <>
                              <Badge
                                bg="secondary"
                                text="light"
                                key={`${summaryItem.segment}-${techItem}-1`}>
                                {techItem}
                              </Badge>{' '}
                            </>
                          );
                        })}
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
              <Card.Body>
                <LoadingButton />
                {showProjectBuilt && (
                  <Alert key="alert-1" variant="success" className="mt-3">
                    <span>Success! We have built your project.</span>
                    <Button
                      variant="link"
                      href="https://app.dataops.live/dataops-demo-project/truedataops-5"
                      target="_new">
                      Go to your project on DataOps.live
                    </Button>
                  </Alert>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={2}></Col>
        </Row>
      </Container>
    </>
  );
}

export default PipelineBuilderView;
