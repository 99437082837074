import React from 'react';
import AppBar from '../components/AppBar';

import MainContainer from '../components/MainContainer';
import PipelineBuilderView from '../views/PipelineBuilderView';

type PipelineBuilderProps = {
  user: any;
}

class PipelineBuilder extends React.Component<PipelineBuilderProps, {}> {

  render() {
    return (
      <div style={{ height: '100%', paddingTop: '40px' }}>
        <AppBar user={this.props.user}/>
        <MainContainer>
          <PipelineBuilderView />
        </MainContainer>
      </div>
    );
  }
}

export default PipelineBuilder;
