import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
interface Props {
  children: React.ReactNode;
}

function MainContainer(props: Props) {
  const style = {
    padding: '0 4rem'
  };
  return (
    <Container fluid>
      <Row>
        <Col>{props.children}</Col>
      </Row>
    </Container>
  );
}

export default MainContainer;
