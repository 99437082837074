import React from 'react';
import './App.scss';
import PipelineBuilder from './routes/PipelineBuilder';
import { useAuth0 } from "@auth0/auth0-react";

// class App extends React.Component {

export default function App() {

  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (isAuthenticated) {
    return (
      <div style={{ height: '100%', paddingTop: '40px' }}>
        <PipelineBuilder user={user}/>
      </div>
    );
  } else {
    loginWithRedirect();
    return <></>;
  }
}