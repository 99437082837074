import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import './styles/LandingPage.css';
import './styles/FailedPipelineList.css';
import './styles/OverviewCard.css';
import './styles/DataModelsList.css';
import './styles/DataModelDetailsList.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Auth0Provider
              domain="dataops.eu.auth0.com"
              clientId="aXYf6IJ0hLM40l6lGUAsMJmNr38l8mF6"
              redirectUri={window.location.origin}
              organization="org_YMu9qm5oh5c4PwxB"
              audience="https://auth0-jwt-authorizer">
              <App />
            </Auth0Provider>
          }
        />
        {/* <Route path="/overview" element={<Overview />} /> */}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
