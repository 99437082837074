import React from 'react';
import { Link } from 'react-router-dom';

type AppBarProps = {
  user?: any;
}

class AppBar extends React.Component<AppBarProps, {}> {

  render() {
    return (
      <div
        className="navbar navbar-gitlab"
        style={{
          width: '100%',
          backgroundColor: '#29b5e8',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          padding: '0 16px',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          marginBottom: 0,
          zIndex: 1000
        }}>
        <Link to="/">
          <img src="/dataops-logo.png" />
        </Link>
        <button
          style={{
            border: 0,
            color: 'white',
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem 1.3rem',
            margin: '4px 2px',
            fontSize: '12px',
            borderRadius: '4px',
            height: '32px',
            fontWeight: 800
          }}>
          Project Builder
        </button>
        <div className="userinfo display_flex">
          <img src={this.props.user.picture} alt={this.props.user.name} />
        </div>
      </div>
    );
  }
}

export default AppBar;
